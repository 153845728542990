export default {
  blogerName: 'СASAL APOSTAS',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@CasalApostas/about',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/live/_03lb7ajTmE?si=30ydV1jtSc-wl1lY',
    },
    {
      name: 'youtube',
      url: 'https://youtu.be/Y4xjpSrtoO4',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@CasalRealista/videos',
    },
    {
      name: 'youtube',
      url: 'https:/www.youtube.com/@CasaldaPP',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@BiancaQuebraBanca?si=JEKiZeMLJQlmcA6w',
    },
    {
      name: 'telegram',
      url: 'https://t.me/Biancaquebrabanca',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c4ba8e754',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cde305de0',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c003e97f7',
      gameTitle: 'Jet Air',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c32bd7ff8',
      gameTitle: 'Alien Fruits ',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c1ee5beca',
      gameTitle: 'Doors of Sol',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c6bcb4f58',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cb006a71e',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c146b9682',
      gameTitle: 'Doors Of Fresh',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>QUEBRABRANCA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'QUEBRABRANCA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
